<template>
  <Toast />
  <ul class="list-none p-0 m-0 flex align-items-center font-medium">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">REPORTES</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active">INFORME MENSUAL Y GESTIÓN</span>
    </li>
  </ul>

  <div class="surface-section px-4 py-5 md:px-12 lg:px-12" style="border: 1px solid #000000">
    <div class="flex align-items-center flex-column lg:justify-content-center lg:flex-row">
      <span style="border-bottom: 1px solid #005f91; font-size: 1.5rem"><strong>INFORME POR MES Y GESTIÓN</strong></span>
    </div>
    
    <div class="col-12">
      <div class="p-fluid formgrid grid p-fondo">
        <div class="field col-12 md:col-3">
          <span><strong>SELECCIONE SUCURSAL: </strong></span>
          <div class="p-inputgroup">
            <Dropdown
              :disabled="true"
              v-model="sucursalSelected"
              :options="sucursales"
              optionLabel="nombre"
              optionValue="id"
              placeholder="Seleccione una sucursal"
            />
          </div>
        </div>

        <div class="field col-12 md:col-3">
          <span><strong>SELECCIONE MES: </strong></span>
          <div class="p-inputgroup">
            <Dropdown
              v-model="mes_seleccionado"
              :options="meses"
              optionLabel="label"
              placeholder="Seleccione un mes"
            />
          </div>
        </div>

        <div class="field col-12 md:col-3">
          <span><strong>SELECCIONE AÑO: </strong></span>
          <div class="p-inputgroup">
            <InputNumber
              v-model="anio_gestion"
              :min="2021"
              :max="new Date().getFullYear()"
              :showButtons="true"
              mode="decimal"
              :useGrouping="false"
            />
          </div>
        </div>

        <div class="field col-12 md:col-3">
          <span><strong>ACCIONES: </strong></span>
          <Button
            label="APLICAR FILTROS"
            icon="pi pi-search"
            class="p-button-info"
            v-tooltip.top="'Aplicar Filtros de Busqueda'"
            :loading="enviando"
            :disabled="enviando"
            @click="BuscarFiltro"
          />
        </div>
      </div>

      <div class="p-fluid formgrid grid mt-2">
        <div class="field col-12">
          <!-- Listado de semanas -->
          <div v-if="semana1.length || semana2.length || semana3.length || semana4.length || semana5.length">
            <template v-for="(semana, index) in [semana1, semana2, semana3, semana4, semana5]" :key="index">
              <div v-if="semana.length > 0" class="mb-4">
                <h3 class="estilo_dias">Semana {{ index + 1 }}</h3>
                <table class="table">
                  <thead>
                    <tr>
                      <th>Fecha</th>
                      <th>Cant. Productos</th>
                      <th>Total Productos</th>
                      <th>Cant. Servicios</th>
                      <th>Total Servicios</th>
                      <th>Total Día</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(dato, idx) in semana" :key="idx">
                      <td>{{ formatFecha(dato.fecha) }}</td>
                      <td>{{ sinDecimales(dato.cant_productos) }}</td>
                      <td>{{ convertirNumeroGermanicFormat(dato.total_productos) }}</td>
                      <td>{{ sinDecimales(dato.cant_servicios) }}</td>
                      <td>{{ convertirNumeroGermanicFormat(dato.total_servicios) }}</td>
                      <td>{{ convertirNumeroGermanicFormat(dato.total_dia) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </template>
          </div>
          <div v-else>
            <p>No hay datos disponibles para el mes seleccionado.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VentaService from "@/service/VentaService";
import SucursalService from "@/service/SucursalService";
import { useAuth } from "@/stores";

export default {
  data() {
    return {
      enviando: false,
      sucursalSelected: 0,
      sucursales: [{ id: 0, nombre: "TODAS" }],
      meses: [
        { label: "ENERO", value: 1 },
        { label: "FEBRERO", value: 2 },
        { label: "MARZO", value: 3 },
        { label: "ABRIL", value: 4 },
        { label: "MAYO", value: 5 },
        { label: "JUNIO", value: 6 },
        { label: "JULIO", value: 7 },
        { label: "AGOSTO", value: 8 },
        { label: "SEPTIEMBRE", value: 9 },
        { label: "OCTUBRE", value: 10 },
        { label: "NOVIEMBRE", value: 11 },
        { label: "DICIEMBRE", value: 12 },
      ],
      mes_seleccionado: null,
      anio_gestion: new Date().getFullYear(),
      semana1: [],
      semana2: [],
      semana3: [],
      semana4: [],
      semana5: [],
    };
  },
  created() {
    this.ventaService = new VentaService();
    this.sucursalService = new SucursalService();
    this.auth = useAuth();
  },
  mounted() {
    this.cargarSucursales();
    this.mes_seleccionado = this.meses.find(
      (mes) => mes.value === new Date().getMonth() + 1
    );
  },
  methods: {
    BuscarFiltro() {
      this.semana1 = [];
      this.semana2 = [];
      this.semana3 = [];
      this.semana4 = [];
      this.semana5 = [];
      this.enviando = true;
      this.cargarReporteMensualGestion();
    },
    cargarReporteMensualGestion() {
      let datos = {
        sucursal_id: this.sucursalSelected,
        anio_gestion: this.anio_gestion,
        mes_seleccionado: this.mes_seleccionado.value,
      };
      this.ventaService
        .filtrarReporteMensualGestion(datos)
        .then((data) => {
          this.semana1 = data.datos.semana1 ?? [];
          this.semana2 = data.datos.semana2 ?? [];
          this.semana3 = data.datos.semana3 ?? [];
          this.semana4 = data.datos.semana4 ?? [];
          this.semana5 = data.datos.semana5 ?? [];
          this.enviando = false;
        })
        .catch((error) => {
          this.enviando = false;
          console.log(error);
        });
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(numero);
    },
    sinDecimales(numero) {
      return (Math.round(numero * 10) / 10).toLocaleString("es-ES", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },
    cargarSucursales() {
      this.sucursalService
        .getSucursalesAll()
        .then((data) => {
          data.forEach((sucursal) => {
            this.sucursales.push(sucursal);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    formatFecha(fecha) {
      //formato 16/09/2021
      let date = new Date(fecha);
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      return `${day < 10 ? "0" + day : day}/${month < 10 ? "0" + month : month}/${year}`;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/demo/badges.scss";

.p-fondo {
  background-color: #f1f8fd;
  border: 1px solid #0093d4;
  border-radius: 8px;
}

.table th, .table td {
  text-align: center;
}

.estilo_dias {
  text-align: left;
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 8px;
  border-bottom: 2px solid #ccc;
}
</style>
